.contact_us {
  width: 100vw;
  .welcome_cu {
    height: 100vh;
    width: 100vw;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .cu_info {
    height: 70vh;
    width: 100vw;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/ContactUs%2FIMG_2900-min.JPG?alt=media&token=b58c013e-6870-46d0-85e4-809c92e0bf63");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    //border: 2px solid orange;
    .info_txt {
      h1 {
        width: 100%;
        border-bottom: 2px solid #fff;
        // font-family: "Quilon", sans-serif;
        text-align: left;
        font-size: 7.5vmin;
        text-transform: uppercase;
        color: #fff;
      }
      h2 {
        color: #722424;
        text-align: justify;
        margin-top: 7vh;
      }
      width: 65%;
      position: absolute;
      top: 30%;
      left: 35%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      -moz-transform: translate(-50%, -50%); /* Firefox */
      -ms-transform: translate(-50%, -50%); /* IE 9 */
    }
  }
  @media (max-width: 1024px) {
    .cu_info {
      height: 70vh;
      .info_txt {
        text-align: center;
        width: 80%;
        top: 40%;
        left: 50%;
        h1 {
          //text-align: center;
          width: 90%;
        }
        h2 {
          font-size: 5vmin;
        }
      }
    }
  }
  .cu_form {
    height: 100vh;
    width: 100vw;
    display: flex;
    .cu_form_img {
      //border: 2px solid orange;
      height: 100%;
      flex: 50%;
      //background-image: url("../../Assets//blurEffect.svg");
      //object-fit: contain;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .cu_form_txt {
      background-color: rgb(26, 26, 26);
      height: 100%;
      flex: 50%;
    }
  }
  @media (max-width: 1024px) {
    .cu_form {
      height: 200vh;
      flex-direction: column;
      .cu_form_img {
        width: 100%;
        height: 40%;
      }
      .cu_form_txt {
        width: 100%;
        height: 60%;
      }
    }
  }
  .cu_stores {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    div {
      position: relative;
      margin: auto;
      //border: 2px solid orange;
      width: 30%;
      height: 90%;
      object-fit: cover;
      img {
        border-radius: 10px;
        height: 100%;
        width: 100%;
      }
      .overlay {
        //overflow: hidden;
        border-radius: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #00000085;
        overflow: hidden;
        width: 100%;
        height: 50%;
        transition: 0.5s ease;
      }

      .text {
        //  text-align: justify;
        width: 80%;
        color: white;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        P {
          font-size: 3vmin;
          margin-top: 5vh;
          text-align: center;
        }
      }
      .button {
        position: absolute;
        top: 80%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background-color: #4c72af;
        border: none;
        color: white;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        //margin: 4px 2px;
        cursor: pointer;
        text-transform: uppercase;
      }
    }
    // div:hover .overlay {
    //   height: 50%;
    // }
  }
  @media (max-width: 1024px) {
    .cu_stores {
      flex-direction: column;
      height: 250vh;

      div {
        //border: 2px solid orange;
        margin-top: 10vh;
        height: 30%;
        width: 80%;
        .overlay {
          height: 100%;
          .text {
            top: 10%;
            p {
              font-size: 5vmin;
            }
          }
        }
      }
    }
  }
  .emnam {
    height: 100vh;
    width: 100vw;
  }
}
