@import url("https://fonts.googleapis.com/css?family=Roboto:700");
@keyframes showTopText {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  40%,
  60% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes showline {
  0% {
    transform: translate3d(-100%, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.animated-title2 {
  //border: 2px solid rgb(255, 255, 255);
  //border: 2px solid orange;
  color: rgb(0, 0, 0);
  font-family: "Quilon", sans-serif;
  height: 50%;
  left: 22%;
  position: absolute;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 38%;
}
@media (max-width: 1024px) {
  .animated-title2 {
    color: rgb(0, 0, 0);
    font-family: "Quilon", sans-serif;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 60%;
  }
}

.animated-title2 > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.animated-title2 > div div {
  font-size: 13vmin;
  padding: 2vmin 0;
  position: absolute;
}
.animated-title2 > div div span {
  width: 100%;
  //   border: 2px solid #fff;
  display: flex;
  h1 {
    justify-content: right;
  }
}
.animated-title2 > div.text-top {
  animation: showline 0.5s;
  border-bottom: 0.7vmin solid rgb(0, 0, 0);
  top: 0;
}
.animated-title2 > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}
.animated-title2 > div.text-top div span:first-child {
  color: #000000;
}
.animated-title2 > div.text-bottom {
  bottom: 0;
}
.animated-title2 > div.text-bottom div {
  font-size: 5vmin;
  text-align: left;
  animation: showBottomText 0.5s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
}
