* {
  margin: 0;
  padding: 0;
  //overflow-x: hidden;
}

.homepage {
  width: 100vw;

  .welcome_hp {
    background: linear-gradient(
      270.47deg,
      #9e4242 48.99%,
      #ce9c6a 49%,
      #ffc489 99.63%
    );
    height: 100vh;
    width: 100vw;
    position: relative;
    .hp_girl {
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      -moz-transform: translate(-50%, -50%); /* Firefox */
      -ms-transform: translate(-50%, -50%); /* IE 9 */
      img {
        height: 100%;
      }
    }
    .hp_back {
      overflow-x: hidden;
      height: 100%;
      width: 100%;
      display: flex;
      .back_left {
        position: relative;
        // border: 2px solid orange;
        flex: 51.5%;
        height: 100%;
        .button_hp {
          animation: slidein 3s linear;
        }
      }
      .back_right {
        //position: relative;
        flex: 48.5%;
        height: 100%;
        .spinner {
          //overflow: hidden;
          height: 50%;
          width: 50%;
          position: absolute;
          top: 50%;
          left: 60%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
          -moz-transform: translate(-50%, -50%); /* Firefox */
          -ms-transform: translate(-50%, -50%); /* IE 9 */
          img {
            height: 100%;
            width: 100%;
          }
          // animation: rotation 5s infinite;
          animation: rotation 8s linear infinite, sizing 1s linear;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .welcome_hp {
      background: linear-gradient(
        0deg,
        #9e4242 48.99%,
        #ce9c6a 49%,
        #ffc489 99.63%
      );
      //flex-direction: column;
      .hp_girl {
        //border: 2px solid orange;
        height: 50%;
        width: 50%;
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
      }
      .hp_back {
        flex-direction: column;
        .back_left {
          height: 50%;
          width: 100%;
        }
        .back_right {
          height: 50%;
          width: 100%;
          .spinner {
            display: none;
          }
        }
      }
    }
  }
  .hp_products {
    height: 100vh;
    width: 100vw;
  }
  .be_awsome {
    margin-top: 5vh;
    height: 40vh;
    width: 100vw;
    //background-image: url("https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/brown-hazel-eye-color.jpg?alt=media&token=ca58e339-5c20-448f-91e0-6edeea454268");
    background-color: #ffe2c5;

    background-size: cover;
    position: relative;

    // background: #ce9c6a;
    // transform: rotate(70deg);

    h1 {
      font-size: 8vmin;
      margin: auto;
      width: 90%;
      color: #722424;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      -moz-transform: translate(-50%, -50%); /* Firefox */
      -ms-transform: translate(-50%, -50%); /* IE 9 */
    }
  }
  .find_store {
    //margin-top: 5vh;
    height: 30vh;
    width: 100vw;
    background-color: #ffe2c5;
    position: relative;
    .button {
      border-radius: 15px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-color: #722424;
      border: none;
      color: white;
      padding: 10px 40px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      // font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      text-transform: uppercase;
      p {
        margin: 0;
        font-size: 1em;
      }
    }

    // background: #ce9c6a;
    // transform: rotate(70deg);
  }
  .about_hp {
    // position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    .about_hp_1 {
      position: relative;
      width: 50%;
      height: 100%;
      p {
        text-align: justify;
        font-size: 4vmin;
        width: 90%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
      }
    }
    .about_hp_2 {
      position: relative;
      width: 50%;
      height: 100%;
      border-radius: 10px;
      video {
        //border: 2px solid orange;
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
        object-fit: contain;
        height: 90%;
        width: 90%;
      }
      div {
        z-index: 0;
        background-color: #9e4242;
        position: absolute;
        top: 45%;
        left: 55%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
        object-fit: cover;
        height: 70%;
        width: 70%;
      }
    }
  }
  @media (max-width: 1024px) {
    .about_hp {
      flex-direction: column;
      .about_hp_1 {
        width: 100%;
        height: 50%;
      }
      .about_hp_2 {
        width: 100%;
        height: 50%;
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes sizing {
  0% {
    transform: scale(10);
    -webkit-transform: scale(10);
  }

  100% {
    transform: scale(2);
    -webkit-transform: scale(1);
  }
}
