* {
  margin: 0;
  padding: 0;
}

.car3 {
  position: absolute;
  height: 100%;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  div {
    // margin-top: 20vh;
    position: relative;
    height: 100%;
    width: 100%;
    img {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px;
      // border: 2px solid #fff;
      border-radius: 15px;
      height: 80%;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@media (max-width: 800px) {
  .car3 {
    width: 90vw;
    height: 50vh;
  }
}
