.products_main {
  //   background-color: #9e4242;
  //border: 2px solid violet;
  width: 100vw;
  //margin-bottom: 10vh;
  .products_title {
    width: 100%;
    height: 40vh;
    text-align: right;
    position: relative;
    h1 {
      font-size: 9vmin;
      margin-left: 2vw;
      width: 40%;
      text-align: left;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      //   -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      //   -moz-transform: translate(-50%, -50%); /* Firefox */
      //   -ms-transform: translate(-50%, -50%); /* IE 9 */
      //   position: absolute;
      //width: 50%;
      border-bottom: 2px solid #000000;
      color: rgb(0, 0, 0);
    }
    p {
      margin-bottom: 3vh;
      margin: auto;
      margin-top: 5vh;
      text-align: left;
      width: 90%;
      font-size: 3vmin;
    }
  }
  .products_images {
    // background-image: url("../../Assets/grunge-paint-background.jpg");
    // border: 2px solid violet;
    height: auto;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .products_image {
      margin: 10px;
      height: 300px;
      height: 300px;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
      img:hover {
        transform: scale(1.5);
        transition: 0.3s;
      }
      overflow: hidden;
    }
  }
}

.products_main_color {
  background-color: #ffc489;
}
