.products {
  background-image: url("../../Assets/SL_042620_30310_36.jpg");
  width: 100vw;
  object-fit: cover;

  .products_welcome {
    height: 100vh;
    width: 100vw;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/kohinoor-optics.appspot.com/o/desk-concept-frame-with-items%20(1)-min.jpg?alt=media&token=3ebb26a5-9144-4739-ac76-5d68908699f5");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    div {
      //width: 50%;
      width: 70%;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      -moz-transform: translate(-50%, -50%); /* Firefox */
      -ms-transform: translate(-50%, -50%); /* IE 9 */
      h1 {
        text-align: center;
        border-bottom: 2px solid rgb(36, 36, 36);
        font-size: 13vmin;
        width: 100%;
        color: rgb(36, 36, 36);
      }
    }
  }
  .products_div {
    height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    //flex-wrap: wrap;
    div {
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

      height: 70%;
      width: 30%;
      margin: auto;
      border-radius: 20px;
      position: relative;

      h1 {
        width: 90%;
        z-index: 10;
        color: rgb(102, 0, 0);
        font-size: 7vmin;
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
      }

      img:hover {
        transform: scale(1.5);
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: 0.3s;
      }
      overflow: hidden;
    }
  }
  @media (max-width: 1024px) {
    .products_div {
      height: auto;
      flex-direction: column;
      div {
        margin: auto;
        margin-top: 5vh;
        width: 70%;
      }
    }
  }
}
