@import url("https://fonts.googleapis.com/css?family=Roboto:700");
@keyframes showTopText {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  40%,
  60% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes showline {
  0% {
    transform: translate3d(-100%, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.animated-title {
  //border: 2px solid orange;
  color: #222;
  font-family: "Quilon", sans-serif;
  height: 70%;
  left: 40%;
  position: absolute;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 70%;
}
@media (max-width: 1024px) {
  .animated-title {
    //border: 2px solid orange;
    color: #222;
    font-family: "Quilon", sans-serif;
    height: 70%;
    left: 50%;
    position: absolute;
    top: 60%;
    transform: translate(-50%, -50%);
    width: 70%;
  }
}
.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.animated-title > div div {
  font-size: 7vmin;
  padding: 2vmin 0;
  position: absolute;
}
@media (max-width: 1024px) {
  .animated-title > div div {
    font-size: 5vmin;
  }
}
.animated-title > div div span {
  text-align: center;
  display: flex;
}
.animated-title > div.text-top {
  animation: showline 0.5s;
  border-bottom: 0.7vmin solid #000;
  top: 0;
}
.animated-title > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}
.animated-title > div.text-top div span:first-child {
  color: #903838;
}

.animated-title > div.text-bottom {
  bottom: 0;
}
.animated-title > div.text-bottom div {
  font-size: 5vmin;
  text-align: left;
  animation: showBottomText 0.5s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
}

@media (max-width: 1024px) {
  .animated-title > div.text-bottom div {
    font-size: 5vmin;
  }
}

.animated-title {
  .text-bottom {
    span {
      margin-left: auto;
      font-size: 7vmin;
      line-height: 5vh;
      // marginLeft: "13vw",
      //         fontSize: "7vmin",
      //         lineHeight: "5vh",
    }
  }
}
