* {
  margin: 0;
  padding: 0;
}

.car {
  //border: 2px solid orange;
  //margin: auto;
  height: 45vh;
  width: 95vw;
  //align-items: center;

  div {
    position: relative;
    height: 100%;
    width: 100%;
    img {
      height: 70%;
      width: 70%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      -moz-transform: translate(-50%, -50%); /* Firefox */
      -ms-transform: translate(-50%, -50%); /* IE 9 */
    }
  }
}
