* {
  margin: 0;
  padding: 0;
}

.car2 {
  margin: auto;

  height: 100vh;
  width: 95vw;
  align-items: center;

  div {
    // margin-top: 20vh;
    position: relative;
    height: 100%;
    width: 100%;
    img {
      object-fit: cover;
      // border: 2px solid #fff;
      height: 95%;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@media (max-width: 800px) {
  .car2 {
    height: 50vh;
    div {
      // margin-top: 20vh;
      position: relative;
      height: 100%;
      width: 100%;
      img {
        object-fit: contain;
      }
    }
  }
}
