* {
  margin: 0px;
  padding: 0px;
}

.doc_profile2 {
  margin: 10px;
  margin-bottom: 5vh;
  display: flex;
  height: 100vh;
  width: 100vw;

  .doc_img2 {
    flex: 50%;
    height: 100%;
    position: relative;
    img {
      z-index: 1;
      border-radius: 10px;
      //border-radius: 30%;
      object-fit: contain;
      height: 70%;
      width: 70%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      -moz-transform: translate(-50%, -50%); /* Firefox */
      -ms-transform: translate(-50%, -50%); /* IE 9 */
    }
    div {
      z-index: 0;
      background-color: #722424;
      border-radius: 10px;
      height: 70%;
      width: 50%;
      position: absolute;
      top: 55%;
      left: 45%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      -moz-transform: translate(-50%, -50%); /* Firefox */
      -ms-transform: translate(-50%, -50%); /* IE 9 */
    }
  }

  .doc_des2 {
    flex: 50%;
    height: 100%;
    position: relative;
    img {
      height: 70%;
      width: 70%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      -moz-transform: translate(-50%, -50%); /* Firefox */
      -ms-transform: translate(-50%, -50%); /* IE 9 */
    }
  }
}
@media (max-width: 800px) {
  .doc_profile2 {
    margin: 0;
    height: 100vh;
    flex-direction: column;
    .doc_des2 {
      height: 50%;
      width: 100%;
      h1 {
        margin-top: 5vh;
        margin-left: 9vw;
        width: 50%;
        font-size: 5vmin;
      }
      h3 {
        margin-left: 9vw;
        font-size: 4vmin;
      }
      p {
        font-size: 3.5vmin;
      }
    }
    .doc_img2 {
      height: 50%;
      width: 100%;
    }
  }
}
