* {
  margin: 0px;
  padding: 0px;
}

.header {
  z-index: 999;
  //   box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
  // rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  width: 100vw;
  height: 15vh;
  align-items: center;
  justify-content: space-around;
  display: flex;
  transition: 0.5s all ease-in;
  position: fixed;
  .logo {
    //margin-left: 3vw;
    height: 175%;

    margin-right: auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .elements {
    display: none;
  }
  button:focus {
    outline: 0;
  }
  .nav_icon {
    //margin-left: auto;
    position: sticky;
    //border: 2px solid orange;
    height: 100%;
    width: 10%;

    .menu {
      height: 100%;
      width: 100%;

      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 0;
      svg {
        margin: auto;
        //border: 2px solid rgb(0, 255, 13);
        height: 90%;
        width: 90%;
      }
    }

    .line {
      fill: none;
      stroke: #ffffff;
      stroke-width: 6;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .line1 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
    .line2 {
      stroke-dasharray: 40 60;
      stroke-width: 6;
    }
    .line3 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
    .opened .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }
    .opened .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 6;
    }
    .opened .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 6;
    }

    // border: 2px solid orange;

    // width: 10%;
    // height: 100%;
    // .ham {
    //   //padding: 10px;
    //   margin: auto;
    //   border: 2px solid orange;
    //   display: flex;
    //   flex-direction: column;

    //   color: orange;
    //   justify-content: space-around;
    //   div {
    //     border-radius: 3px;
    //     height: 5px;
    //     width: 50px;
    //     background-color: orange;
    //   }
    // }
    // .nav_icons {
    //   height: 100%;
    //   width: 50%;
    // }
  }

  @media (max-width: 1024px) {
    .elements {
      text-transform: uppercase;
      display: flex;
      width: 70%;
      justify-content: space-around;
      p {
        font-weight: 500;
        //font-size: 2.4vmin;
      }
    }
  }
}

.open_header {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.767);
  height: 70vh;
  transition: 0.5s all ease-in;
  flex-direction: column;
  .logo {
    // border: 2px solid orange;
    text-align: left;
    height: 30%;
    width: 100%;
  }
  .elements {
    height: 70%;
    width: 100%;
    display: flex;
    // border: 2px solid orange;
    align-items: center;
    justify-content: space-around;

    a {
      //font-family: "Stardom", sans-serif;
      //   font-family: "Switzer", sans-serif;
      font-family: "Quilon", sans-serif;
      font-size: 6vmin;
      background: linear-gradient(
        to right,
        #bf953f,
        #fcf6ba,
        #b38728,
        #fbf5b7,
        #aa771c
      );

      //   background-clip: text;
      //   -webkit-background-clip: text;
      //   -webkit-text-fill-color: transparent;
      //   animation: textclip 1.5s linear infinite;
      //   background: linear-gradient(
      //     to right,
      //     #b45cc3 0%,
      //     #b53fc5 0%,
      //     #7684ff 90%
      //   );
      background-size: auto auto;
      background-clip: border-box;
      background-size: 200% auto;
      color: #fff;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: textclip 3s linear infinite;
      display: inline-block;
    }
  }

  .nav_icon {
    height: 20%;
  }
}
@media (max-width: 1024px) {
  .header {
    .elements {
      display: none;
    }
    .nav_icon {
      height: 70%;
      width: 30%;
    }
    .logo {
      height: 100%;
    }
  }
  .open_header {
    height: 100vh;
    .elements {
      height: 80%;
      flex-direction: column;
      display: flex;
    }

    .nav_icon {
      //border: 2px solid orange;
      height: 20%;
      width: 30%;
    }
  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }

  60% {
    background-position: 180px;
  }

  100% {
    background-position: 180px;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
