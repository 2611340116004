* {
  margin: 0;
  padding: 0;
}
.footer-clean {
  text-align: justify;
  border-top: 2px solid #000;
}
@media (max-width: 800px) {
  .footer-clean {
    object-fit: fill;
    text-align: justify;
  }
}

.footer-clean {
  padding: 50px 0;
  background-color: #722424;
  color: #ffffff;
}

.footer-clean h3 {
  letter-spacing: 5px;
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 3.5vmin;
}
@media (max-width: 600px) {
  .footer-clean {
    h3 {
      font-size: 7vmin;
    }
  }
}
@media (min-width: 600px) {
  .footer-clean {
    h3 {
      font-size: 2vmin;
    }
  }
}
@media (min-width: 1200px) {
  .footer-clean {
    h3 {
      font-size: 3.5vmin;
    }
  }
}

.footer-clean ul {
  font-weight: 600;
  letter-spacing: 2px;
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-clean ul a {
  margin-left: 1vw;
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

@media (max-width: 800px) {
  .footer-clean {
    ul {
      a {
        margin-left: 3vw;
      }
    }
  }
}

.footer-clean ul a:hover {
  opacity: 1;
}

.footer-clean .item.social {
  text-align: right;
}

@media (max-width: 767px) {
  .footer-clean .item {
    text-align: center;
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .footer-clean .item.social {
    text-align: center;
  }
}

.footer-clean .item.social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-top: 22px;
  color: inherit;
  opacity: 0.75;
}

.footer-clean .item.social > a:hover {
  opacity: 0.9;
}

@media (max-width: 991px) {
  .footer-clean .item.social > a {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .footer-clean .item.social > a {
    margin-top: 10px;
  }
}

.footer-clean .copyright {
  margin-top: 14px;
  margin-bottom: 0;
  font-size: 13px;
  opacity: 0.6;
}

/* Credit to https://epicbootstrap.com/snippets/footer-with-columns */

.phone_ion {
  margin: auto;
}
