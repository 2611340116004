.wa {
  margin-top: 45vh;
  margin-left: 95vw;
  z-index: 999;
  height: 50px;
  width: 50px;
  position: fixed;

  img {
    margin-top: 15px;
    transition: 0.5s;
    // margin: 10px;
    height: 70%;
    width: 70%;
  }
  img:hover {
    transform: scale(1.5);
  }
  #ins {
    img {
      margin-top: 10px;
      height: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .wa {
    margin-top: 45vh;
    margin-left: 90vw;
  }
}
