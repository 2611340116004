* {
  margin: 0px;
  padding: 0px;
}

.doc_profile {
  margin: 10px;
  margin-bottom: 15vh;
  display: flex;
  height: 50vh;
  width: 100vw;

  .doc_img {
    margin-bottom: 5vh;
    margin-top: 5vh;
    flex: 40%;
    height: 100%;
    img {
      border-radius: 30%;
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  .doc_des {
    flex: 60%;
    height: 100%;
    h3 {
      margin-bottom: 5vh;
      margin-left: 2vw;
      text-align: left;
    }

    h1 {
      margin-left: 2vw;
      text-align: left;
      padding-bottom: 10px;
      border-bottom: 2px solid #47608e;
      width: 30%;
    }
    p {
      text-align: justify;
      font-size: 4vmin;
      margin: auto;
      width: 85%;
    }
  }
}
@media (max-width: 800px) {
  .doc_profile {
    margin: 0;
    height: auto;
    flex-direction: column;
    .doc_des {
      height: 50%;
      width: 100%;
      h1 {
        margin-top: 5vh;
        margin-left: 9vw;
        width: 50%;
        font-size: 5vmin;
      }
      h3 {
        margin-left: 9vw;
        font-size: 4vmin;
      }
      p {
        font-size: 3.5vmin;
      }
    }
    .doc_img {
      height: 50%;
      width: 100%;
    }
  }
}
