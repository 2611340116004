.about_us {
  overflow-x: hidden;
  width: 100%;
  .welcome_au {
    height: 100vh;
    width: 100vw;
    background-image: url("../../Assets/about.svg");
    object-fit: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    //position: relative;
    .button {
      border-radius: 15px;
      position: absolute;
      top: 80%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-color: #722424;
      border: none;
      color: white;
      padding: 10px 40px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      // font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      text-transform: uppercase;
      p {
        margin: 0;
        font-size: 1em;
      }
    }
  }
  @media (max-width: 1024px) {
    .welcome_au {
      height: 100vh;
    }
  }
  // .about_info {
  //   height: 100vh;
  //   width: 100vw;
  // }
  .info_decade {
    height: 100vh;
    width: 100vw;
    display: flex;
    .decade_txt {
      flex: 50%;
      height: 100%;
    }
    .decade_img {
      position: relative;
      flex: 50%;
      height: 100%;
      img {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
        object-fit: cover;
        height: 70%;
        width: 70%;
      }
      div {
        z-index: 0;
        position: absolute;
        background-color: #722424;
        height: 70%;
        width: 70%;
        top: 45%;
        left: 55%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
      }
    }
  }
  .welcome_services {
    height: 100vh;
    width: 100vw;
    display: flex;

    .services_img {
      position: relative;
      //border: 2px solid rgb(34, 155, 23);
      border-radius: 10px;

      //margin: auto;
      width: 50%;
      height: 100%;
      // img {
      //   padding-right: 70px;
      //   border-right: 5px solid #47608e;
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      //   -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      //   -moz-transform: translate(-50%, -50%); /* Firefox */
      //   -ms-transform: translate(-50%, -50%); /* IE 9 */

      //   height: 60%;
      //   width: 80%;
      // }
    }
    .services_txt {
      flex: 50%;
      height: 100%;
      position: relative;
      text-align: left;
      h1 {
        text-align: left;
        color: #000;
        padding-bottom: 10px;
        border-bottom: 2.5px solid #000000;
        width: 70%;
        font-size: 5vmin;
        position: absolute;
        top: 15%;
        left: 40%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
      }
      p {
        font-size: 3vmin;
        justify-content: center;
        width: 80%;
        position: absolute;
        top: 60%;
        left: 45%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
      }
    }
  }
  @media (max-width: 800px) {
    .welcome_services {
      height: 150vh;
      flex-direction: column;
      .services_img {
        width: 100%;
        height: 40%;
        img {
          margin-top: 10vh;
          border: none;
          height: 100%;
        }
      }
      .services_txt {
        width: 100%;
        height: 60%;
        p {
          letter-spacing: 2px;
          text-align: justify;
          width: 90%;
          font-size: 4vmin;
          top: 60%;
          left: 50%;
        }
        h1 {
          left: 45%;
          top: 0%;
          font-size: 7vmin;
        }
      }
    }
  }
  .info_head {
    // background-image: url(../../Assets//cut.svg);
    //position: relative;
    height: 20vh;
    width: 100vw;
    position: relative;
    h1 {
      font-size: 8vmin;
      margin-top: 4vh;
      color: black;
    }
  }
}

//-------------------------------------------------------------------------------------
// Angled Edges v2.0.0 (https://github.com/josephfusco/angled-edges)
// Copyright 2017 Joseph Fusco
// Licensed under MIT (https://github.com/josephfusco/angled-edges/blob/master/LICENSE)
//-------------------------------------------------------------------------------------

/// Replace `$search` with `$replace` in `$string`.
///
/// @author Hugo Giraudel
/// @link   http://www.sassmeister.com/gist/1b4f2da5527830088e4d
///
/// @param  {String}  $string - Initial string
/// @param  {String}  $search - Substring to replace
/// @param  {String}  $replace ('') - New value
/// @return {String}  Updated string
///
@function ae-str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      ae-str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

/// Encode SVG to use as background.
///
/// @param  {String}  $string
/// @return {String}  Encoded svg data
///
@function ae-svg-encode($string) {
  $result: ae-str-replace(
    $string,
    "<svg",
    '<svg xmlns="http://www.w3.org/2000/svg"'
  );
  $result: ae-str-replace($result, "%", "%25");
  $result: ae-str-replace($result, '"', "'");
  $result: ae-str-replace($result, "<", "%3C");
  $result: ae-str-replace($result, ">", "%3E");

  @return "data:image/svg+xml," + $result;
}

/// Outputs pseudo content for main mixin.
///
/// @author Joseph Fusco
///
/// @param  {String}  $location
/// @param  {Number}  $height
/// @output psuedo content
///
@mixin ae-pseudo($wedge, $height, $width) {
  background-image: url($wedge);
  background-position: center center;
  background-repeat: no-repeat;

  // full width wedge - needed as Firefox ignores preserveAspectRatio="none" in this case
  @if ($width == null) {
    background-size: 100% 100%;
  }

  content: "";
  height: $height * 1px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
}

/// Attatches an svg wedge shape to an element.
///
/// @author Joseph Fusco
///
/// @param  {String}  $location   - 'inside top', 'outside top', 'inside bottom', 'outside bottom'
/// @param  {String}  $hypotenuse - 'upper left', 'upper right', 'lower left', 'lower right'
/// @param  {Color}   $fill
/// @param  {Number}  $height
/// @param  {Number}  $width
/// @output '::before' and/or '::after' with svg background image
///
@mixin angled-edge($location, $hypotenuse, $fill, $height: 100, $width: null) {
  position: relative;

  $points: (
    "upper left": "0,#{$height} #{$width},#{$height} #{$width},0",
    "upper right": "0,#{$height} #{$width},#{$height} 0,0",
    "lower left": "0,0 #{$width},#{$height} #{$width},0",
    "lower right": "0,0 #{$width},0 0,#{$height}",
  );

  // full width wedge
  @if ($width == null) {
    $points: (
      "upper left": "0,#{$height} 100,#{$height} 100,0",
      "upper right": "0,#{$height} 100,#{$height} 0,0",
      "lower left": "0,0 100,#{$height} 100,0",
      "lower right": "0,0 100,0 0,#{$height}",
    );
  }

  // ensure $fill color is using rgb()
  $fill-rgb: "rgb(" + round(red($fill)) + "," + round(green($fill)) + "," +
    round(blue($fill)) + ")";

  // capture alpha component of $fill to use with fill-opacity
  $fill-alpha: alpha($fill);

  $wedge: '<svg width="#{$width}" height="#{$height}" fill="#{$fill-rgb}" fill-opacity="#{$fill-alpha}"><polygon points="#{map-get($points, $hypotenuse)}"></polygon></svg>';

  // full width wedge
  @if ($width == null) {
    $wedge: '<svg preserveAspectRatio="none" viewBox="0 0 100 #{$height}" fill="#{$fill-rgb}" fill-opacity="#{$fill-alpha}"><polygon points="#{map-get($points, $hypotenuse)}"></polygon></svg>';
  }

  $encoded-wedge: ae-svg-encode($wedge);

  @if ($location == "inside top") {
    &::before {
      @include ae-pseudo($encoded-wedge, $height, $width);
      top: 0;
    }
  } @else if ($location == "outside top") {
    &::before {
      @include ae-pseudo($encoded-wedge, $height, $width);
      top: -$height * 1px;
    }
  } @else if ($location == "inside bottom") {
    &::after {
      @include ae-pseudo($encoded-wedge, $height, $width);
      bottom: 0;
    }
  } @else if ($location == "outside bottom") {
    &::after {
      @include ae-pseudo($encoded-wedge, $height, $width);
      bottom: -$height * 1px;
    }
  } @else {
    @error 'Invalid argument for $location - must use: `inside top`, `outside top`, `inside bottom`, `outside bottom`';
  }
  @if (map-has-key($points, $hypotenuse) == false) {
    @error 'Invalid argument for $hypotenuse - must use: `upper left`, `upper right`, `lower left`, `lower right`';
  }
}

// styles

.hero {
  width: 100%;
  //width: 100vw;
  margin-bottom: 20vh;
  position: relative;
  height: 300px;
  background-color: #722424;
  @include angled-edge("outside bottom", "lower right", #722424, 100);
  h1 {
    font-size: 7vmin;
    // width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
    -moz-transform: translate(-50%, -50%); /* Firefox */
    -ms-transform: translate(-50%, -50%); /* IE 9 */
  }
}
