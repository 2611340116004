.btn {
  position: absolute;
  top: 70%;
  left: 14%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
  -moz-transform: translate(-50%, -50%); /* Firefox */
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 2px solid #e74c3c;
  border-radius: 0.6em;
  color: #e74c3c;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  h1 {
    color: #f1c40f;
    font-size: 4rem;
    text-transform: uppercase;
    display: block;
    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    h1 {
      font-size: 3rem;
    }
  }
}

@media (max-width: 1024px) {
  .btn {
    margin: 0;
    top: 100%;
    left: 50%;
    padding: 15px;
  }
}

.btn:hover,
.btn:focus {
  color: #fff;
  outline: 0;
}

.third {
  border-color: #903838;
  color: #fff;
  box-shadow: 0 0 40px 40px #903838 inset, 0 0 0 0 #903838;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.third:hover {
  box-shadow: 0 0 10px 0 #903838 inset, 0 0 10px 4px #903838;
  a {
    text-decoration: none;
    color: #000;
  }
}

a {
  color: #fff;
  text-decoration: none;
}
