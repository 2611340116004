.vision_care {
  width: 100vw;
  .vc_welcome {
    height: 100vh;
    width: 100vw;
    background-image: url("../../Assets//vc_girl.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    div {
      //width: 50%;
      width: 90%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      -moz-transform: translate(-50%, -50%); /* Firefox */
      -ms-transform: translate(-50%, -50%); /* IE 9 */
      h1 {
        text-align: left;
        border-bottom: 2px solid rgb(36, 36, 36);
        font-size: 13vmin;
        width: 60%;
        color: rgb(36, 36, 36);
      }
    }
  }
  .vc_info {
    background-color: #ad5757;
    height: 40vh;
    width: 100vw;
    position: relative;
    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
      -moz-transform: translate(-50%, -50%); /* Firefox */
      -ms-transform: translate(-50%, -50%); /* IE 9 */
    }
  }
  @media (max-width: 1024px) {
    .vc_info {
      h1 {
        width: 90%;
        font-size: 10vmin;
      }
    }
  }
  .vc_hc {
    height: 100vh;
    width: 100vw;
  }
}
